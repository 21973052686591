import Whatsapp from "../assets/images/whatsapp.png";

const WhatsappChat = () => {
  return (
    <div className="fixed bottom-16 right-5 w-14 h-14 cursor-pointer hover:scale-110 shadow-lg animate-bounce delay-75 shadow-green-600 rounded-full bg-green-400">
      <a
        href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Whatsapp} alt="" className="" />
      </a>
    </div>
  );
};

export default WhatsappChat;
