import person from "../../assets/icons/person.svg";
import check from "../../assets/icons/check.svg";
import payment from "../../assets/icons/payment.svg";
import group from "../../assets/images/group.png";
import frame1 from "../../assets/images/frame1.png";
import frame2 from "../../assets/images/frame2.png";
import frame3 from "../../assets/images/frame3.png";
import frame4 from "../../assets/images/frame4.png";
import Pos from "../../assets/images/pos_full.png";

import deposit from "../../assets/icons/deposit.svg";
import airtime from "../../assets/icons/airtime.svg";
import savings from "../../assets/icons/savings.svg";
import electricity from "../../assets/icons/electricity.svg";
import tv from "../../assets/icons/tv.svg";
import fast from "../../assets/icons/fast.svg";

import Achievement from "../../components/Achievement";
import FeaturesCard from "../../components/FeaturesCard";
import FAQs from "../../components/FAQs";

const Home = () => {
  return (
    <div className="" id="home">
      <div className="bg-heroBg bg-cover pt-24 md:pt-36">
        <div className="container mx-auto flex justify-center px-3">
          <div className="flex flex-col justify-center items-center md:w-[60%] gap-4 ">
            <div className="text-blue font-bold text-[38px] text-center md:text-[54px]">
              Become an <span className="text-[#2F82C4]">AWAPAY</span> Agent
            </div>
            <div className="text-black text-center font-normal text-lg md:px-32">
              Get a POS terminal and experience cheap transaction fees on all
              your transactions.
            </div>
            <div className="flex gap-4">
              <a
                href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <button className="outline-none bg-[#2F82C4] p-3 px-5 text-white rounded-[4px] font-medium text-sm">
                  Get your Pos
                </button>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <button className="outline-none text-[#2F82C4] p-3 px-5 border border-[#2F82C4]  rounded-[4px] font-medium text-sm">
                  Contact Sales
                </button>
              </a>
            </div>
            <img src={group} alt="" className="w-[50%] pt-10" />
          </div>
        </div>
      </div>
      <div className="bg-blue">
        <div className="container mx-auto flex md:items-center justify-start pl-16 md:pl-0">
          <div className="flex flex-col md:flex-row items-start md:items-center py-10 justify-evenly gap-8 md:gap-5 md:w-full">
            <Achievement count="100%" icon={check} text="Succes Rate" />
            <Achievement count="1000+" icon={person} text="Business Agents" />
            <Achievement
              count="10 milllion+"
              icon={payment}
              text="Processed Monthly"
            />
          </div>
        </div>
      </div>
      <div className="bg-white" id="howitworks">
        <div className="container mx-auto py-16 px-4 md:px-20">
          <div className="flex flex-col justify-center items-center gap-10 text-black  ">
            <div className="text-blue font-bold text-3xl md:text-4xl">
              How to get your POS
            </div>
            <div className="text-black text-lg md:text-xl font-normal">
              Follow the steps below to get your own POS terminal
            </div>
          </div>
          {/* Frame 1 */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-5 md:gap-24 pt-16">
            <div className="flex flex-col gap-4 md:w-1/2">
              <div className="font-bold text-2xl">
                1. Register an agent account
              </div>
              <div className="font-medium text-base leading-8">
                Fill out the registration form: You will need to provide some
                personal information, such as your name, email address, and
                phone number. You will also need to provide some additional
                information such as your business name, website, utility bill,
                and valid national ID.
              </div>
            </div>
            <div className="md:w-1/2">
              <img src={frame1} alt="" />
            </div>
          </div>
          {/* Frame 2 */}
          <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-5 md:gap-24 pt-16">
            <div className="flex flex-col gap-4 md:w-1/2">
              <div className="font-bold text-2xl">
                2. Acquire a new POS Terminal
              </div>
              <div className="font-medium text-base leading-8">
                Request for a new POS terminal. you may need to verify your
                account by providing additional information or uploading some
                documents to complete the terminal acquisition process.
              </div>
            </div>
            <div className="md:w-1/2">
              <img src={frame3} alt="" />
            </div>
          </div>
          {/* Frame 3 */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-5 md:gap-24 pt-16">
            <div className="flex flex-col gap-4 md:w-1/2">
              <div className="font-bold text-2xl">3. KYC Verification</div>
              <div className="font-medium text-base leading-8">
                We will review your application and get your account verified in
                less than 24 hours. Once approved, you will be verified as an
                agent and we would get your POS terminal delivered to you.
              </div>
            </div>
            <div className="md:w-1/2">
              <img src={frame2} alt="" />
            </div>
          </div>
          {/* Frame 4 */}
          <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-5 md:gap-24 pt-16">
            <div className="flex flex-col gap-4 md:w-1/2">
              <div className="font-bold text-2xl">4. Start Transacting</div>
              <div className="font-medium text-base leading-8">
                Fund your terminal account and start doing transactions. As part
                of our commitment to building with you, we will assign a
                dedicated Relationship Manager to you.
              </div>
            </div>
            <div className="md:w-1/2">
              <img src={frame4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-footerBg bg-cover" id="features">
        <div className="container mx-auto  py-16 px-4 md:px-20">
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-10 md:w-[60%]">
              <div className="text-blue font-bold text-3xl md:text-4xl text-center">
                Generate your own income
              </div>
              <div className="text-black text-lg md:text-xl font-normal text-center">
                As an Awapay Merchant, you have the power and control to earn as
                much money as you can by providing banking services in your
                community.
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-16 pt-16">
              <FeaturesCard
                icon={fast}
                heading="Fast & Secure Transfers"
                text="With your Awapay account, you can send and receive money in real-time from any bank for as low as 10 Naira."
              />
              <FeaturesCard
                icon={deposit}
                heading="Deposit / Withdraw"
                text="Become a bank for your neighbourhood. Awapay empowers you to offer deposit and withdrawal services to all customers."
              />
              <FeaturesCard
                icon={savings}
                heading="Savings"
                text="Make your money work for you! Earn up to 15% per annum interest when you save in any of our savings products."
              />
              <FeaturesCard
                icon={electricity}
                heading="Electricity"
                text="Offer people an easier way to pay their electricity bills on the Awapay app. Easy payment, more money."
              />
              <FeaturesCard
                icon={tv}
                heading="TV subscriptions"
                text="Earn more money on commission when you help your customers pay for their TV subscriptions. Zero Fees!"
              />
              <FeaturesCard
                icon={airtime}
                heading="Airtime / Data"
                text="Get discounts and vouchers for airtime purchase on the Awapay app. Pay Less for More with ease on Awapay!"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="" id="faq">
        <div className="container mx-auto  flex flex-col justify-center gap-4 md:gap-8 items-center text-black py-20 px-4 md:px-20">
          <FAQs />
        </div>
      </div>
      <div className="bg-posBg md:bg-heroBg bg-cover mt-10 md:pt-0">
        <div className="container mx-auto py-10 pt-44 md:pt-48 md:py-40 px-4 md:px-20">
          <div className="relative flex flex-col md:flex-row items-center bg-posBg bg-cover md:rounded-3xl text-white md:px-44 md:py-24">
            <div className="flex flex-col items-center md:items-start gap-3 pt-48 md:pt-0 md:w-1/2">
              <div className="font-bold text-2xl">Get Started Now</div>
              <div className="text-lg font-normal text-center md:text-start">
                Choose a POS device that simplifies your company operations.
              </div>
              <div className="flex gap-4">
                <a
                  href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="outline-none bg-white p-3 px-5 text-blue rounded-[4px] font-medium text-sm">
                    Get your Pos
                  </button>
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="outline-none text-white p-3 px-5 border border-white  rounded-[4px] font-medium text-sm">
                    Contact Sales
                  </button>
                </a>
              </div>
            </div>
            <img
              className="absolute bottom-[70%] md:bottom-8  md:right-44 w-[170px] md:w-[220px]"
              src={Pos}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
