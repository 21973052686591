import React from "react";
type FeaturesCardPropType = {
  icon: string;
  heading: string;
  text: string;
};

const FeaturesCard = ({ heading, icon, text }: FeaturesCardPropType) => {
  return (
    <div className="flex flex-col gap-5 justify-start items-center md:items-start text-black hover:rounded-lg hover:shadow-2xl hover:bg-white p-5">
      <img src={icon} alt={heading} />
      <div className="font-bold text-lg">{heading}</div>
      <div className="font-normal text-base text-center md:text-start">
        {text}
      </div>
    </div>
  );
};

export default FeaturesCard;
