import { useState } from "react";

const FAQs = () => {
  const [businessFaqs, setBusinesFaqs] = useState([
    {
      id: 1,
      open: false,
      isOn: false,
      heading: "How AWAPay works?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
    {
      id: 2,
      open: false,
      isOn: false,
      heading: "How can I get started?",
      content:
        "Fill out the registration form: You will need to provide some personal information, such as your name, email address, and phone number. You will also need to provide some additional information such as your business name, website, utility bill, and valid national ID.",
    },
    {
      id: 3,
      open: false,
      isOn: false,
      heading: "Who uses AWAPay?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
  ]);

  const toggleBusinessFaq = (id: number) => {
    setBusinesFaqs(
      businessFaqs.map((faq) =>
        faq.id === id ? { ...faq, open: !faq.open } : faq
      )
    );
  };

  const toggleOnBusiness = (id: number) => {
    setBusinesFaqs(
      businessFaqs.map((faq) =>
        faq.id === id ? { ...faq, isOn: !faq.isOn } : faq
      )
    );
  };

  // const [isOn, setIsOn] = useState(false);
  return (
    <div className="md:w-[60%]">
      <div className=" flex flex-col justify-center gap-4 md:gap-8 items-center">
        <div className="flex flex-col justify-center items-center gap-10 ">
          <div className="text-blue font-bold text-3xl md:text-4xl text-center">
            Frequently asked Questions
          </div>
          <div className="text-black text-lg md:text-xl font-normal text-center">
            Get answers to your questions about Awapay POS terminal. Feel free
            to contact us if you don’t find an answer here.
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-10 md:mt-20">
        <div>
          {businessFaqs.map((item) => (
            <div
              key={item.id}
              className={`border-b-2 border-b-[#F4F4F4] py-3 px-0 md:px-5 cursor-pointer`}
              onClick={() => toggleBusinessFaq(item.id)}
              onMouseOver={() => {
                toggleOnBusiness(item.id);
              }}
              onMouseLeave={() => toggleOnBusiness(item.id)}
            >
              <div className="flex justify-between items-center">
                <div className="font-medium text-base md:text-lg">
                  {item.heading}
                </div>
                {!item.open ? (
                  <div
                    className={`text-center rounded-[4px] bg-lightBlack w-6 h-6  ${
                      item.isOn ? "bg-black text-white" : ""
                    }`}
                  >
                    <div
                      className={`text-center ${
                        item.isOn ? "animate-wiggle " : ""
                      }`}
                    >
                      +
                    </div>
                  </div>
                ) : (
                  <div className="rounded-[4px]  w-6 h-6 bg-black text-white ">
                    <div className="hover:animate-wiggle text-center">-</div>
                  </div>
                )}
              </div>
              <div
                className={`pt-3 text-[#646464] text-[14.4px] ${
                  item.open ? "block" : "hidden"
                }`}
              >
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
