import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import WhatsappChat from "./components/WhatsappChat";

function App() {
  return (
    <BrowserRouter>
      <WhatsappChat />
      <Header />
      <div className="">
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Contact />} path="/" />
          <Route path="/*" element={<>Not Found</>} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
