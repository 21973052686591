type AchievementPropType = {
  icon: string;
  count: string;
  text: string;
};
const Achievement = ({ count, icon, text }: AchievementPropType) => {
  return (
    <div className="flex gap-7 md:gap-7 text-white items-center ">
      <img src={icon} alt="" className="w-7 md:w-8 h-7 md:h-8" />
      <div className="flex flex-col justify-center items-start md:items-center font-bold gap-1 md:gap-3">
        <div className="text-3xl md:text-4xl">{count}</div>
        <div className="text-sm md:text-base">{text}</div>
      </div>
    </div>
  );
};

export default Achievement;
