/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as RLink } from "react-scroll";
import { ReactComponent as Arrow } from "../assets/icons/arrow-down.svg";

import logo from "../assets/icons/awapay.svg";

const Footer = () => {
  const navigate = useNavigate();

  const [companyLink, setCompanyLink] = useState([
    {
      to: "/",
      open: false,
      text: "Terms of Use",
    },
    {
      to: "/",
      open: false,
      text: "Privacy Policy",
    },
  ]);
  const [socialLinks, setSociaLinks] = useState([
    {
      to: "https://twitter.com",
      open: false,
      text: "Twitter",
    },
    {
      to: "https://facebook.com",
      open: false,
      text: "Facebook",
    },
    {
      to: "https://instagram.com",
      open: false,
      text: "Instagram",
    },
    {
      to: "https://linkedin.com",
      open: false,
      text: "LinkedIn",
    },
  ]);
  const [contactLink, setContactLink] = useState([
    {
      to: "tel:+0123456789",
      open: false,
      text: "01 2345 6789",
    },
    {
      to: "mailto:support@awapay.com",
      open: false,
      text: "support@awapay.com",
    },
    {
      to: "mailto:help@awapay.com",
      open: false,
      text: "help@awapay.com",
    },
  ]);

  const [company, setCompany] = useState(false);
  const [social, setSocial] = useState(false);
  const [contact, setContact] = useState(false);

  const toggleCompany = () => {
    setCompany(!company);
  };
  const toggleSocial = () => {
    setSocial(!social);
  };
  const toggleContact = () => {
    setContact(!contact);
  };

  return (
    <div className="">
      <div className="container mx-0 lg:mx-auto flex flex-col justify-center items-center py-10 md:py-12 mt-12">
        <div className="flex flex-col lg:flex-row items-center md:items-start justify-center lg:justify-between w-full px-4 md:px-32 lg:px-20 pt-12 md:pt-20 gap-0 md:gap-2 ">
          <div className="flex flex-col justify-center items-center md:items-start gap-5 md:gap-7 md:w-[50%]">
            <RLink to="home" smooth spy>
              <img src={logo} alt="" />
            </RLink>
            <div className="text-[14px] md:text-base lg:text-base text-center md:text-start text-black leading-6 md:leading-9">
              Get a POS terminal and experience cheap transaction fees on all
              your transactions.
            </div>
          </div>
          <div className="flex flex-col w-full justify-start items-start md:hidden pt-14 gap-8">
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleCompany()}
              >
                <div className=" text-black font-bold text-base">Company</div>
                <Arrow className={`${!company ? "" : "rotate-180"}`} />
              </div>
              <div className={`${company ? "block" : "hidden"}`}>
                {companyLink.map((item) => (
                  <div
                    className="text-black font-normal text-base mt-6"
                    key={item.text}
                  >
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleSocial()}
              >
                <div className=" text-black font-bold text-base">Social</div>
                <Arrow className={`${!social ? "" : "rotate-180"}`} />
              </div>
              <div className={`${social ? "block" : "hidden"}`}>
                {socialLinks.map((item) => (
                  <div
                    className="text-black font-normal text-base mt-6"
                    key={item.text}
                  >
                    <a href={item.to} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleContact()}
              >
                <div className=" text-black font-bold text-base">Contact</div>
                <Arrow className={`${!contact ? "" : "rotate-180"}`} />
              </div>
              <div className={`${contact ? "block" : "hidden"}`}>
                {contactLink.map((item) => (
                  <div
                    className="text-black font-normal text-base mt-6"
                    key={item.text}
                  >
                    <a href={item.to} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-col md:flex-row justify-evenly items-start w-full gap-3">
            <div className="flex flex-col justify-center items-start">
              <div className="font-bold text-black text-[22px] ">COMPANY</div>
              <div>
                {companyLink.map((item) => (
                  <div className="text-black mt-6" key={item.to}>
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="font-bold text-black text-[22px] ">SOCIAL</div>
              <div>
                {socialLinks.map((item) => (
                  <div className="text-black mt-6" key={item.to}>
                    <a href={item.to} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="font-bold text-black text-[22px] ">Contact</div>
              <div>
                {contactLink.map((item) => (
                  <div className="text-black mt-6" key={item.to}>
                    <a href={item.to} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="font-bold text-black text-sm md:text-lg lg:text-base mt-20">
          AWAPAY | All Rights Reserved 2023
        </div>
      </div>
    </div>
  );
};

export default Footer;
