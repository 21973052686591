import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import Logo from "../assets/icons/awapay.svg";
import Menu from "../assets/icons/menu-black.svg";
import SideMenu from "./SideMenu";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScroll(window.pageYOffset > 0)
      );
    }
  }, []);

  const onClickMenu = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <>
      {showSideNav && <SideMenu onClickMenu={onClickMenu} />}
      <div
        className={`flex fixed top-0  justify-between items-center py-3 px-4 md:px-32 lg:px-20 w-full z-[8] ${
          scroll ? "bg-white" : "bg-transparent"
        }`}
      >
        <div className="container mx-auto flex py-0 md:py-4 justify-between items-center px-0 md:px-5">
          <Link to="home" smooth spy>
            <img src={Logo} alt="" />
          </Link>
          <div className="hidden md:flex gap-10 text-black font-normal text-base">
            <Link
              activeClass="active"
              to="howitworks"
              smooth
              spy
              className="cursor-pointer"
            >
              How It Works
            </Link>
            <Link
              activeClass="active"
              to="features"
              smooth
              spy
              className="cursor-pointer"
            >
              Features
            </Link>
            <Link
              activeClass="active"
              to="faq"
              smooth
              spy
              className="cursor-pointer"
            >
              FAQs
            </Link>
          </div>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B2349062002122&text=Hello%2C+my+name+is%3F&type=phone_number&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <button className="hidden md:block outline-none bg-[#2F82C4] p-3 px-5 text-white rounded-[4px] font-medium text-sm">
              Get your Pos
            </button>
          </a>
          <img
            src={Menu}
            className="block md:hidden w-8"
            alt=""
            onClick={() => setShowSideNav(!showSideNav)}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
