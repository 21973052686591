import React from "react";
import Logo from "../assets/icons/awapay.svg";
import Close from "../assets/icons/close.svg";
import { Link } from "react-scroll";

type PropType = {
  onClickMenu: () => void;
};

const SideMenu = ({ onClickMenu }: PropType) => {
  return (
    <div className="bg-heroBg bg-white bg-cover z-[9] fixed top-0 left-0 right-0 bottom-0 ">
      <div className="fixed flex flex-col top-0 bottom-0 left-0 right-0 px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="home" smooth spy onClick={onClickMenu}>
            <img src={Logo} alt="" />
          </Link>
          <img src={Close} alt="" className="w-6" onClick={onClickMenu} />
        </div>
        <div className="flex flex-col justify-between mt-32 mb-10 h-full">
          <div className="flex flex-col justify-center items-center  gap-10 text-black font-normal text-base">
            <Link
              activeClass="active"
              to="howitworks"
              smooth
              spy
              className="cursor-pointer text-2xl"
              onClick={onClickMenu}
            >
              How It Works
            </Link>
            <Link
              activeClass="active"
              to="features"
              smooth
              spy
              className="cursor-pointer text-2xl"
              onClick={onClickMenu}
            >
              Features
            </Link>
            <Link
              activeClass="active"
              to="faq"
              smooth
              spy
              className="cursor-pointer text-2xl"
              onClick={onClickMenu}
            >
              FAQs
            </Link>
          </div>
          <button className="outline-none bg-[#2F82C4] p-6 px-5 text-white rounded-[4px] font-medium text-base">
            Get your Pos
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
